<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Notification List
      </h3>
    </div>
    <div class="panel">
      <div v-if="this.activeName === 'pending'">
        <el-form
          :model="date"
          ref="date"
          label-position="top"
          :inline="true"
          size="mini"
        >
          <el-form-item label="Campaign name">
            <el-input
              v-model="campaign_name"
              @keyup.enter.native="getNotificationsWithFilter()"
              placeholder="Campaign name"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="Campaign type">
            <el-select v-model="campaign_type" placeholder="Сонгох">
              <el-option
                v-for="item in campaignTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Notification type">
            <el-select v-model="notification_type" placeholder="Сонгох">
              <el-option
                v-for="item in notificationTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="Notification type 1">
            <el-select v-model="notification_type1" placeholder="Сонгох">
              <el-option
                v-for="item in notificationTypes1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item prop="start_date" label="Эхлэх огноо">
            <el-date-picker
              v-model="date.start_date"
              type="datetime"
              placeholder="Эхлэх огноо"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions1"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="end_date" label="Дуусах огноо">
            <el-date-picker
              v-model="date.end_date"
              type="datetime"
              placeholder="Дуусах огноо"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions2"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="Ажилласан">
            <el-switch v-model="is_worked" />
          </el-form-item>
          <el-form-item :label="whitespace">
            <el-button
              type="primary"
              @click="getNotifications()"
              icon="el-icon-search"
              >Хайх
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-else-if="this.activeName === 'worked'">
        <el-form label-position="top" :inline="true" size="mini">
          <el-form-item label="Campaign name">
            <el-input
              v-model="logs.campaign_name"
              placeholder="Campaign name"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="start_date" label="Эхлэх огноо">
            <el-date-picker
              v-model="logs.start_date"
              type="datetime"
              placeholder="Эхлэх огноо"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions1"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="end_date" label="Дуусах огноо">
            <el-date-picker
              v-model="logs.end_date"
              type="datetime"
              placeholder="Дуусах огноо"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions2"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="Ажилласан">
            <el-switch v-model="logs.is_worked" />
          </el-form-item>
          <el-form-item :label="whitespace">
            <el-button
              type="primary"
              @click="getNotificationLogs()"
              icon="el-icon-search"
              >Хайх
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="Notifications" name="pending">
          <el-table :data="notificationList">
            <el-table-column prop="campaign_name" label="Campaign name">
            </el-table-column>
            <el-table-column prop="work_at" label="Work date">
              <template slot-scope="scope">
                {{
                  scope.row.work_at === "Invalid Date"
                    ? "Null"
                    : scope.row.work_at
                }}
              </template>
            </el-table-column>
            <el-table-column prop="campaign_type" label="Type">
              <template slot-scope="scope">
                {{
                  scope.row.campaign_type == "notification" ? "Firebase" : "SMS"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="title" label="Title"> </el-table-column>
            <el-table-column prop="body" label="Body"> </el-table-column>
            <el-table-column prop="img_url" label="Image">
              <template slot-scope="scope">
                <img
                  :src="scope.row.img_url"
                  width="100px"
                  alt=""
                  class="icon-image"
                />
              </template>
            </el-table-column>
            <el-table-column prop="send_type" label="Send type">
              <template slot-scope="scope">
                {{ scope.row.send_type == "single" ? "List" : "All" }}
              </template>
            </el-table-column>
            <el-table-column prop="is_worked" label="Is worked" width="120px">
              <template slot-scope="scope">
                <el-tag
                  size="mini"
                  effect="dark"
                  :type="scope.row.is_worked == false ? 'danger' : 'success'"
                >
                  {{ scope.row.is_worked == false ? "Not Worked" : "Worked" }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="Устгах" width="85px">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="deleteNotification(scope.row.id)"
                ></el-button>
              </template>
            </el-table-column>
            <el-table-column label="Дэлгэрэнгүй" width="120px">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="getNotificationDetail(scope.row.id)"
                  >Details</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="mt20 mb20 text-right"
            background
            layout="total, prev, pager, next"
            :current-page="notificationCurrentpage"
            :page-count="notificationPageCount"
            :total="notificationPageTotal"
            :page-size="notificationLogsPageSize"
            @current-change="getNotifications"
          >
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="Notification logs" name="worked">
          <el-table :data="notificationLogList">
            <el-table-column
              prop="notification.campaign_name"
              label="Campaign name"
            >
            </el-table-column>
            <el-table-column prop="work_at" label="Work date">
              <template slot-scope="scope">
                {{
                  scope.row.work_at === "Invalid Date"
                    ? "Null"
                    : scope.row.work_at
                }}
              </template>
            </el-table-column>
            <el-table-column prop="is_worked" label="Is worked" width="120px">
              <template slot-scope="scope">
                {{ scope.row.is_worked == "0" ? "Not Worked" : "Worked" }}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="mt20 mb20 text-right"
            background
            layout="total, prev, pager, next"
            :current-page="notificationLogCurrentpage"
            :page-count="notificationLogPageCount"
            :total="notificationLogPageTotal"
            :page-size="notificationLogsPageSize"
            @current-change="getNotificationLogs"
          >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
export default {
  name: "notificationList",
  mounted() {
    this.getNotifications();
    this.getNotificationLogs();
  },
  data() {
    return {
      logs: {
        campaign_name: "",
        is_worked: false,
        start_date: "",
        end_date: ""
      },
      notificationList: [],
      activeName: "pending",
      date: {
        start_date: null,
        end_date: null
      },
      campaign_name: "",
      campaign_type: "",
      is_worked: false,
      notificationCurrentpage: null,
      notificationPageCount: null,
      notificationPageTotal: "",
      notificationLogsPageSize: "",
      notificationLogList: [],
      notificationLogPageCount: "",
      notificationLogPageTotal: "",
      notificationLogCurrentpage: "",
      whitespace: "\xa0",
      notification_type: "",
      notification_type1: "",
      campaignTypeList: [
        {
          value: "notification",
          label: "Notification"
        },
        {
          value: "sms",
          label: "SMS"
        }
      ],
      notificationTypes1: [
        {
          value: "Topic",
          label: "topic"
        },
        {
          value: "Phone",
          label: "phone"
        }
      ],
      notificationTypeList: [
        {
          value: "all",
          labe: "All"
        },
        {
          value: "scheduled",
          label: "Scheduled"
        },
        {
          value: "dynamic",
          label: "Dynamic"
        }
      ],
      size: 0,
      page: 0,
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      pickerOptions2: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  methods: {
    async getNotifications(page) {
      try {
        let isValidStartDate = this.date.start_date !== null;
        let isValidEndDate = this.date.end_date !== null;
        if (isValidStartDate && isValidEndDate) {
          if (this.date.start_date > this.date.end_date) {
            this.$notify({
              title: "Анхааруулга",
              message: "Сонгосон хугацаагаар хайх боломжгүй байна",
              type: "error"
            });
            return false;
          }
        } else if (
          (isValidStartDate && !isValidEndDate) ||
          (isValidEndDate && !isValidStartDate)
        ) {
          this.$notify({
            title: "Анхааруулга",
            message: "Эхлэх болон дуусах хугацааг сонгоно уу",
            type: "error"
          });
          return false;
        }
        let payload = {
          notification_type: this.notification_type,
          campaign_name: this.campaign_name,
          campaign_type: this.campaign_type,
          is_worked: this.is_worked,
          start_date: this.date.start_date,
          end_date: this.date.end_date,
          page: page ? page : 1
        };
        service
          .getNotifications(payload)
          .then(res => {
            if (res.status == "success") {
              this.notificationList = res.data.data;
              this.notificationPageCount = res.data.last_page;
              this.notificationPageTotal = res.data.total;
              this.notificationCurrentpage = res.data.current_page;
              this.notificationLogsPageSize = res.data.per_page;
              // this.orderLoading = false
            } else {
              this.$notify({
                title: "Анхааруулга",
                message: "Алдаа: " + res.message,
                type: "error"
              });
            }
          })
          .catch(error => {
            this.$notify({
              title: "Анхааруулга",
              message: "Алдаа: " + error.message,
              type: "error"
            });
          });
      } catch (err) {
        // this.orderLoading = false
        this.$notify.error({
          title: "Алдаа",
          message: err.message
        });
      }
    },
    async getNotificationLogs(page) {
      try {
        let isValidStartDate = this.date.start_date !== null;
        let isValidEndDate = this.date.end_date !== null;
        if (isValidStartDate && isValidEndDate) {
          if (this.date.start_date > this.date.end_date) {
            this.$notify({
              title: "Анхааруулга",
              message: "Сонгосон хугацаагаар хайх боломжгүй байна",
              type: "error"
            });
            return false;
          }
        } else if (
          (isValidStartDate && !isValidEndDate) ||
          (isValidEndDate && !isValidStartDate)
        ) {
          this.$notify({
            title: "Анхааруулга",
            message: "Эхлэх болон дуусах хугацааг сонгоно уу",
            type: "error"
          });
          return false;
        }
        let payload = {
          campaign_name: this.logs.campaign_name,
          is_worked: this.logs.is_worked,
          start_date: this.logs.start_date,
          end_date: this.logs.end_date,
          page: page ? page : 1
        };
        service
          .getNotificationLogs(payload)
          .then(res => {
            if (res.status == "success") {
              this.notificationLogList = res.data.data;
              this.notificationLogPageCount = res.data.last_page;
              this.notificationLogPageTotal = res.data.total;
              this.notificationLogCurrentpage = res.data.current_page;
              this.notificationLogsPageSize = res.data.per_page;
              // this.orderLoading = false
            } else {
              this.$notify({
                title: "Анхааруулга",
                message: "Алдаа: " + res.message,
                type: "error"
              });
            }
          })
          .catch(error => {
            this.$notify({
              title: "Анхааруулга",
              message: "Алдаа: " + error.message,
              type: "error"
            });
          });
      } catch (err) {
        // this.orderLoading = false
        this.$notify.error({
          title: "Алдаа",
          message: err.message
        });
      }
    },
    deleteNotification(id) {
      service
        .deleteNotification({ notification_id: id })
        .then(res => {
          if (res.status == "success") {
            this.getNotifications();
            this.getNotificationLogs();
            this.$notify({
              title: "Амжилттай",
              message: "Амжилттай устгалаа",
              type: "success"
            });
          } else {
            this.$notify({
              title: "Анхааруулга",
              message: "Алдаа: " + res.message,
              type: "error"
            });
          }
        })
        .catch(error => {
          this.$notify({
            title: "Анхааруулга",
            message: "Алдаа: " + error.message,
            type: "error"
          });
        });
    },
    getNotificationDetail(id) {
      this.$router.push({
        name: `editNotification`,
        params: { id: id, type: "edit" }
      });
    }
  }
};
</script>
